(function () {
  angular.module('7Shop.Core')
    .provider('GravityApplication', GravityApplication);

  /**
   * @memberOf module:"7Shop.Core"
   * @class GravityApplication
   */
  function GravityApplication() {
    var self = this;
    var getApp = ($http, baseUrl, companyUuid) => $http.get(baseUrl + '/apps/' + companyUuid);

    this._data = {};

    /**
     * @memberOf module:"7Shop.Core".GravityApplication
     */
    this.setAppName = function (appName) {
      this._data.appName = appName;
    };

    /**
     * @memberOf module:"7Shop.Core".GravityApplication
     */
    this.getAppName = function () {
      return this._data.appName;
    };

    /**
     *
     */
    this.$get = function (
      $http,
      $log,
      NabENV,
      errorParser
    ) {
      /**
     * @memberOf module:"7Shop.Core".GravityApplication
     */
      self.fetchGravityAppName = function (companyUuid) {
        const baseUrl = NabENV.api.gravity.shop.replace('7platform.com', '7platform.info');
        return getApp($http, baseUrl, companyUuid)
          .then((response) => response)
          .catch((err) => {
            $log.warn('[7Shop.Core] Failed fetching gravity company from CF', errorParser.parseUpstream(err));
            return getApp($http, NabENV.api.gravity.shop, companyUuid)
              .then((res) => res)
              .catch((error) => {
                $log.warn('[7Shop.Core] Failed fetching gravity company from CF', errorParser.parseUpstream(error));
                return false;
              });
          });
      };

      return self;
    };
  }
})();
