/**
* @class ErrorParser
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function ErrorParser() {
  this.parseMessage = function (error) {
    return error?.data?.message || error?.response?.message || error?.serverResponse?.message
      || error?.message || error?.msg || error?.data || error?.statusText || '';
  };

  this.parseCode = function (error) {
    return error?.data?.code || error?.response?.status
    || (error?.reason?.length && error?.reason[0]) || error?.data?.clientCode
    || error?.clientCode || error?.status || error?.code;
  };

  this.parse = function (error) {
    return {
      message: this.parseMessage(error),
      code: this.parseCode(error)
    };
  };

  this.parseUpstream = function (error) {
    return {
      upstream_message: this.parseMessage(error),
      upstream_code: this.parseCode(error)
    };
  };
}

export default ErrorParser;
