import { has, find } from 'underscore';
import master from '@nsoft/seven-gravity-gateway/master';

/**
 *
 * @class GravityGatewayIntegrator
 * @memberOf module."7Shop.Common"
 */
/* @ngInject */
function GravityGatewayIntegrator(
  $rootScope,
  $document,
  $injector,
  $q,
  $timeout,
  $log,
  $interpolate,
  GameService,
  barcodeService,
  ShopEnvironment
) {
  var GatewayConstructor = master;
  var Gateway = GatewayConstructor({
    debug: ShopEnvironment.isDebugMode()
  });
  const createOldEvent = (event) => {
    const eventObj = $document[0].createEvent('Events');
    if (eventObj.initEvent) {
      eventObj.initEvent(event.type, false, true);
    }

    eventObj.ctrlKey = event.ctrlKey;
    eventObj.altKey = event.altKey;
    eventObj.shiftKey = event.shiftKey;
    eventObj.keyCode = event.keyCode;
    eventObj.which = event.keyCode;
    return eventObj;
  };

  const createEvent = (slaveEvent) => {
    let finalEvent;
    const modifiedEvent = { ...slaveEvent };

    // gatways sends code under keyboardButton and type under event
    // reassing and delete non standrd property
    modifiedEvent.code = slaveEvent.keyboardButton;
    modifiedEvent.type = slaveEvent.event;
    modifiedEvent.cancelable = true;
    delete modifiedEvent.keyboardButton;
    delete modifiedEvent.event;

    switch (modifiedEvent.type) {
    case 'click':
      finalEvent = new MouseEvent('click', modifiedEvent);
      break;
    case 'keydown':
    case 'keypress':
    case 'keyup':
      finalEvent = new KeyboardEvent(modifiedEvent.type, modifiedEvent);
      // Chrome has bug with creating event in older versions.
      // keyCode is always set to 0 when using KeyboardEvent.
      // If this happens use alternative way
      if (finalEvent.keyCode !== modifiedEvent.keyCode) {
        finalEvent = createOldEvent(modifiedEvent);
      }
      break;
    default:
      finalEvent = new Event(modifiedEvent.type, modifiedEvent);
    }
    return finalEvent;
  };

  function handleRouteSwitch(slaveData, productId) {
    const availableViews = {
      BettingArea: {
        view: productId,
        product: true
      }
    };
    const viewName = slaveData.data.name[0];

    if (!has(availableViews, viewName)) {
      $log.warn('[7Shop.Integrator] Error changing UI to unknown state', {
        product_id: productId,
        code: 'T_UNKNOWN_UI_STATE'
      });
    } else if (viewName === 'BettingArea') {
      GameService.setWidgetStateOnGameEnter();
    }
  }

  function handleUiHide(slaveData, productId) {
    const availableViews = {
      All: {
        newState: {
          calculator: {
            show: false
          },
          ticketList: {
            show: false
          },
          gamesHelp: {
            show: false,
            disableInFooter: true
          },
          ticketSession: {
            show: false
          }
        }
      },
      GamesHelp: {
        newState: {
          gamesHelp: {
            show: false
          }
        }
      },
      BettingArea: {
        newState: {
          calculator: {
            show: false
          },
          ticketList: {
            show: false
          },
          gamesHelp: {
            show: false,
            disableInFooter: true
          }
        }
      }
    };
    const views = slaveData.data.name;

    views.forEach((viewName) => {
      if (!has(availableViews, viewName)) {
        $log.warn('[7Shop.Integrator] Error processing UI.Hide to unknown state', {
          product_id: productId,
          code: 'T_UNKNOWN_UI_STATE',
          viewName
        });
      } else {
        $rootScope.$emit('7S:Widget.UpdateSettings', {
          data: availableViews[viewName].newState
        });
      }
    });
  }

  const handleNotificationsRejectAndResolve = (slaveData, NabNotifications) => {
    NabNotifications = $injector.get('NabNotifications');
    NabNotifications.show({ ...slaveData.data, resolve: slaveData.resolve, reject: slaveData.reject });
  };

  const handleLoaderVisibility = (slaveData) => {
    const loaderService = $injector.get('loaderService');
    if (slaveData.data.isLoading) {
      loaderService.showLoader({
        safeTimerDelay: 10000
      });
    } else {
      loaderService.endLoader();
    }
  };

  this.registeredModules = {};

  Gateway.subscribe(
    '*',
    /**
     *
     * @param {Object} slaveData
     * @param {String} slaveData.action
     * @param {String} slaveData.slaveId
     * @param {String} [slaveData.event]
     * @param {String} [slaveData.altKey]
     * @param {*} slaveData.data
     */
    function (slaveData) {
      var service = null;
      var TicketActions;
      var TicketService;
      var actionParams = slaveData.action.split(':');
      var e;
      var data = slaveData.data;
      const productId = data?.productId || slaveData.slaveId;
      let NabNotifications;

      switch (slaveData.action) {
      case 'Slave.Event':
        // event keys coming from slave
        e = createEvent(slaveData);
        // mark this event as custom event from gravity gateway
        e.gravityGatewayEvent = true;
        if ($document[0].activeElement.nodeName !== 'IFRAME') {
          $document[0].activeElement.dispatchEvent(e);
        } else {
          $document[0].dispatchEvent(e);
        }
        break;
      case 'Tickets:Pay': // this one should be deprecated in favor of Tickets.Pay
      case 'Ticket:Payin.Send': // this one should be deprecated in favor of Tickets.Pay
        $rootScope.$emit('7S:Tickets.Pay', slaveData);
        break;
      case 'Tickets.Update':
        $rootScope.$emit('7S:Tickets.Update', slaveData.data);
        break;
      case 'Tickets.CashOut':
        TicketService = $injector.get('TicketService');
        TicketService.ticketCashout(slaveData.data.ticketOptions, slaveData.data.ticket).then((res) => {
          if (res.error) {
            slaveData.reject(res);
            return;
          }
          slaveData.resolve(res);
        });
        break;
      case 'Tickets.Payout':
        TicketActions = $injector.get('TicketActions');
        TicketActions.payout(slaveData.data.ticket, {
          resolve: slaveData.resolve,
          reject: slaveData.reject
        });
        break;
      case 'ticket:addResponse': // this one should be deprecated in favor of Tickets.Update
        $rootScope.$emit('7S:Tickets.Update', {
          ...slaveData.data,
          action: 'Add',
          printAction: ''
        });
        break;
      case 'ticket:cancelResponse': // this one should be deprecated in favor of Tickets.Update
        $rootScope.$emit('7S:Tickets.Update', {
          ...slaveData.data,
          action: 'Cancel',
          printAction: 'Cancel'
        });
        break;
      case 'ticket:payoutResponse': // this one should be deprecated in favor of Tickets.Update
        $rootScope.$emit('7S:Tickets.Update', {
          ...slaveData.data,
          action: 'Payout',
          printAction: 'Payout'
        });
        break;
      case 'FocusNext':
        service = $injector.get('focusNext');
        service.apply(service, slaveData.data);
        break;
      case 'Notifications.Show':
      case 'Notification:Show':
      case 'Notifications.Clicked':
        service = $injector.get('NabNotifications');

        if (slaveData.data.length && slaveData.data[0].actions) {
          slaveData.data[0].actions.forEach(function (action) {
            action.productId = productId;
          });
        }

        service[actionParams[1].charAt(0).toLowerCase() + actionParams[1].slice(1)].apply(service, slaveData.data);
        break;
      case 'Product.UpdateSettings':
      case 'Product:UpdateSettings': {
        const productData = {
          productId: productId,
          data: {
            shortcuts: data.shortcuts,
            ticketConfig: data.ticketConfig
          }
        };
        $rootScope.$emit('7S:Widget.UpdateProductSettings', productData);
        $rootScope.$emit('7S:Widget.SettingsChanged', productData);
        break;
      }
      case 'UI.Show':
        handleRouteSwitch(slaveData, productId);
        break;
      case 'UI.Hide':
        handleUiHide(slaveData, productId);
        break;
      case 'Slave.ScanFinished':
        barcodeService.emitCode(slaveData.data.code, false);
        break;
      case 'EventPublisher.Publish':
        $rootScope.$emit('7S:EventPublisher.Publish', slaveData.data);
        break;
      case 'Dialog.Show':
      case 'Dialog.ShowModal':
        slaveData.data.productId = productId;
        handleNotificationsRejectAndResolve(slaveData, NabNotifications);
        break;
      case 'Widget.Loading':
        handleLoaderVisibility(slaveData);
        break;
      default:
        slaveData.productId = productId;
        $rootScope.$broadcast('7S:' + slaveData.action, slaveData);
      }
    }
  );

  /**
   *
   * @param {String} productId
   * @param {S_GatewayLoadData} [config]
   */
  function setIframeGameConfig(productId, config) {
    const productConfig = {
      productId: productId,
      data: {
        shortcuts: config.shortcuts,
        ticketConfig: config.ticketConfig,
        tax: config.tax
      }
    };
    if (config.peripherals) {
      if (config.peripherals.printService) {
        $rootScope.$emit('7S:Peripherals.UpdateConfig', {
          productId: productId,
          data: config.peripherals
        });
      }
    }

    if (config.tax) {
      $rootScope.$emit('7S:Peripherals.UpdateConfig', {
        productId: productId,
        data: {
          printService: {
            taxes: config.tax
          }
        }
      });
    }

    if (config && Object.keys(config).length) {
      $rootScope.$emit('7S:Widget.UpdateProductSettings', productConfig);

      $rootScope.$emit('7S:Widget.SettingsChanged', productConfig);
    }
  }

  /**
   * @memberof module."7Shop.Common".GravityGatewayIntegrator
   * @return {*}
   */
  this.getMaster = function () {
    return Gateway;
  };

  /**
   * @memberof module."7Shop.Common".GravityGatewayIntegrator
   * @param {String} id
   */
  this.getModuleByFrameId = function (id) {
    return find(this.registeredModules, (module, key) => id === module?.config?.iframeConfig?.frameId || key);
  };

  /**
   * @memberof module."7Shop.Common".GravityGatewayIntegrator
   * @param {String} id
   */
  this.getModuleByProductId = function (id) {
    return this.registeredModules[id];
  };

  /**
   * @memberof module."7Shop.Common".GravityGatewayIntegrator
   * @param {Object} data
   * @param {String} data.iframeSrc
   * @param {String} data.iframeId
   * @param {Object} data.iframeConfig
   * @param {Boolean} data.iframeConfig.destroy
   * @param {Object} data.iframeConfig.snoozOnInit
   * @param {Object} data.context
   * @param {Object} data.context.data
   * @param {HTMLElement} data.element
   */
  this.registerIframeModule = function (data) {
    const deferred = $q.defer();
    const self = this;
    const frameId = data.iframeConfig?.frameId || data.iframeId;
    const timeoutPromise = $timeout(function (slaveData) {
      // we will rject this promis but iframe will keep loading in background.
      // if we we don't do this, loader could stay in loading state forever
      const MESSAGE = 'Load of iframe took more than 10 sec.';
      const errorData = {
        iframe_id: frameId,
        product_displayid: data.iframeId,
        iframe_src: slaveData.iframeSrc,
        code: 'S_IFRAME_LOAD_TIMEOUT'
      };
      $log.warn(`[7Shop.Common] ${MESSAGE}`, errorData);
      deferred.reject({
        message: MESSAGE,
        ...errorData
      });
    }, 10000, true, data);

    this.registeredModules[data.iframeId] = {
      config: data,
      loaded: false,
      productId: data.iframeId
    };

    // but only register iframe once based on iframe attribute id
    // we don't want to create multiple iframe with same id
    // for an example luckyx and grahynoud races are booted from same iframe
    if (!this.getIframe(frameId)) {
      this.createIframe(data);

      Gateway.addSlave({
        frameId: frameId,
        slaveId: frameId,
        autoResize: false,
        data: data.context.data,
        init: function () {
          // It will be triggered when slave is ready for load.
        },
        load: function () {
          // Callback which will be triggered
          // when slave starts to load
        },
        /**
         *
         * Callback which will trigger when slave is loaded
         * @memberof module."7Shop.Common".GravityGatewayIntegrator
         * @param {Object} slaveData
         * @param {S_GatewayLoadData} slaveData.data
         * @param {String} slaveData.msgSender
         * @param {String} slaveData.slaveId
         */
        loaded: function (slaveData) {
          var iframeModule = self.getModuleByFrameId(slaveData.slaveId);
          // should send here config data
          setIframeGameConfig(
            slaveData.slaveId,
            slaveData.data
          );

          if (data.iframeConfig && data.iframeConfig.snoozOnInit) {
            Gateway.sendMessage(frameId, {
              action: 'Slave.Snooze',
              slaveId: frameId
            });
            Gateway.sendMessage(frameId, {
              action: 'Slave.Mute',
              slaveId: frameId
            });
          }

          $timeout.cancel(timeoutPromise);
          deferred.resolve(iframeModule);
        }
      });
    } else {
      $timeout.cancel(timeoutPromise);
      deferred.reject({
        message: 'Instance of frame already created.',
        code: 'S_IFRAME_ALREADY_CREATED',
        iframe_id: frameId,
        product_displayid: data.iframeId
      });
    }

    return deferred.promise;
  };

  this.getIframe = function (id) {
    var frame = angular.element('iframe#' + id).first();
    return !frame.length ? false : frame;
  };

  function interpolateUrl(url, bindings) {
    var exp = $interpolate(url);
    return exp(bindings || {});
  }

  /**
   * @memberof module."7Shop.Common".GravityGatewayIntegrator
   * @param {Object} data
   * @param {String} data.iframeSrc
   * @param {String} data.iframeId
   * @param {Object} data.iframeConfig
   * @param {Boolean} data.iframeConfig.destroy
   * @param {HTMLElement} data.element
   */
  this.createIframe = function (data) {
    const { iframeSrc } = data;
    const optionalData = data.context.data;
    const bindings = {
      linkId: optionalData.account.linkId,
      tenantUuid: optionalData.tenant.uuid,
      language: optionalData.locale.iso1,
      productDisplayId: optionalData.product.id,
      token: optionalData.user.auth.token
    };
    const url = new URL(interpolateUrl(iframeSrc, bindings));
    url.searchParams.set('integrationType', 'gravityGateway');
    url.searchParams.set('application', 'shop');
    url.searchParams.set('platform', 'seven');
    url.searchParams.set('v', Date.now());
    const frame = angular.element('<iframe>', {
      src: url.toString(),
      id: data.iframeConfig?.frameId || data.iframeId,
      class: 'border-0',
      style: 'visibility: hidden; position: fixed;',
      name: Date.now()
    }).appendTo(data.iframeConfig && data.iframeConfig.destroy ? data.element.children[0] : 'body');
    return frame;
  };
}

export default GravityGatewayIntegrator;
/**
 * Sent when slave is ready for display.
 * @memberof module."7Shop.Common".GravityGatewayIntegrator
 * @event "Slave.Loaded"
 * @type {S_GatewayLoadData}
 */

/**
 * @memberof module."7Shop.Common".GravityGatewayIntegrator
 * @typedef {Object} S_GatewayLoadData
 * @description Configuration data coming from slave integration
 *
 * @property {Object} [tax] - This mandatory if current company has tax option on. It used as decision to print tax or
 *                            not inside ticket template.
 * @property {Object} [tax.payout] - Payout tax definition
 * @property {Boolean} tax.payout.hideTax - Show or hide tax inside print template
 * @property {String} tax.payout.policy - Name of tax policy, it is defined by seven platform
 * @property {Boolean} tax.payout.value=false - Is tax enabled
 * @property {Object} [tax.payin] - Payin tax definition
 * @property {Boolean} tax.payin.hideTax - Show or hide tax inside print template
 * @property {String} tax.payin.policy - Name of tax policy, it is defined by seven platform
 * @property {Boolean} tax.payin.value=false - Is tax enabled
 * @property {Object} peripherals
 * @property {Object} peripherals.printService
 * @property {String} [peripherals.printService.oddsType=Decimal] - Possible values are Decimal, American, Frictional-
 *           It is used inside print service to determinate template behavior based on provided odd type
 * @property {Object.<string, S_Shortcut>} [shortcuts] - List of shortcuts that will be available under Help
 * and emitted back to product on operator action
 * @property {7S_TicketConfig} ticketConfig
 * @property {String} [icon] - Icon class from https://design.nsoft.ba/#!/font-icons/all
 */
