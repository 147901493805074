import { defaults, find } from 'underscore';

(function () {
  angular.module('7Shop.Common')
    .service('Widgets', Widgets);

  /**
   * @memberOf module:"7Shop.Common"
   * @class Widgets
   *
   */
  function Widgets($filter, $templateCache, $log, $q) {
    this._widgets = [];

    /**
     * @memberOf module:"7Shop.Common".Widgets
     */
    this.WIDGET_SOURCE_TYPES = {
      IFRAME: 'IFRAME',
      ANGULARJS_COMPONENTS: 'ANGULARJS_COMPONENT'
    };
    /**
     * @param {Object} widget
     * @param {String} widget.name
     * @param {Array} widget.source
     * @param {String} widget.source.url
     * @param {String} widget.source.type
     * @param {Object} widget.source.element
     * @param {String} widget.source.element.tag
     * @param {Array.<Object>} [widget.positions]
     * @param {String} widget.positions.id
     * @param {Number} [widget.positions.priority=100]
     */
    this.registerWidget = function (widget) {
      var positionsDefault = { priority: 100 };
      var alreadyRegistered = this.getWidgetByName(widget.name);
      var index;
      var source;

      if (alreadyRegistered) {
        $log.warn('[7Shop.Widgets] Widget is already registered', {
          widgetName: widget.name
        });
        return false;
      }

      if (widget.positions) {
        widget.positions.forEach(function (widgetPosition) {
          defaults(widgetPosition, positionsDefault);
        });
      }

      source = widget.source[0];
      switch (source.type) {
      case this.WIDGET_SOURCE_TYPES.IFRAME:
        // no need to do anything right now
        break;
      case this.WIDGET_SOURCE_TYPES.ANGULARJS_COMPONENTS:
        $templateCache.put(widget.name, source.element.tag);
        break;
      default:
        $log.warn('[7Shop.Common] Widget type unknown!', {
          widgetName: widget.name
        });
        return false;
      }

      index = this._widgets.push(widget);
      return this._widgets[index - 1];
    };

    this.getWidgetByName = function (name) {
      return find(this._widgets, function (widget) {
        return name === widget.name;
      });
    };

    /**
     * @memberOf module:"7Shop.Common".Widgets
     */
    this.filterWidgetsFromSource = function (config) {
      return _.filter(config, function filter(value, key) {
        return key.startsWith('widget.');
      });
    };

    /**
     * @memberOf module:"7Shop.Common".Widgets
     */
    this.getByType = function (types, whenScopeData) {
      var foundWidget = false;
      var result = $filter('filter')(this._widgets, function (widget) {
        // TODO: hardcoded to work only item matching in array (anyof)
        return types.indexOf(widget.type) >= 0
          && widget.when.values.indexOf(whenScopeData.value) >= 0
          && widget.active === true;
      });

      if (result && result.length) {
        foundWidget = result[0];
        return $q.resolve(foundWidget);
      }

      return $q.reject({
        message: 'Widget by type not found.'
      });
    };

    this.getWidgetsByPosition = function (position) {
      return $filter('filter')(this._widgets, function (widget) {
        if (widget.positions) {
          return $filter('filter')(widget.positions, { id: position }, true).length;
        }
        return false;
      });
    };
  }
})();
