import angular from 'angular';
import coreModule from '../../core';
import gameModule from '../../games';
import GravityGatewayIntegrator from './gravityGatewayIntegratorService';
import eventDecorator from './rootScopeGravityDecorator';

const moduleName = angular.module('7Shop.Integrator', [coreModule, gameModule])
  .decorator('$rootScope', eventDecorator)
  .service('GravityGatewayIntegrator', GravityGatewayIntegrator)
  .name;

export default moduleName;
