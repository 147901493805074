import { defaults } from 'underscore';

/**
 * @class ShopPrintService
 * @memberof module:"7Shop.Print"
 */
/* @ngInject */
function ShopPrintService(
  $http,
  $q,
  $window,
  $log,
  $rootScope,
  $translate,
  Modules,
  LocalStorage,
  PeripheralsSvc,
  printServiceApi,
  NabENV,
  Widgets,
  GravitySettings,
  errorParser
) {
  var printServiceTypes = {
    nps: 'nps',
    sps: 'sps',
    android: 'android',
    external_printer: 'external_printer'
  };
  var driverState = {
    ready: false,
    type: '',
    status: {}
  };
  var driver = null;
  // SPS detected printers
  var detectedPrinters = null;
  var spsUrl;

  function init() {
    return detectPrintService().then(function (type) {
      return Modules.loadModule(printServiceTypes[type]);
    });
  }

  function setActivePrinter() {
    LocalStorage.getItem('settings.printerId').then(function (printerData) {
      var printer = null;

      try {
        printer = JSON.parse(printerData);
      } catch (e) {
        printer = printerData;
      }

      // every driver must implement init method,
      // here we pass active/saved printer
      getDriver().init({
        selectedPrinter: printer,
        detectedPrinters: detectedPrinters,
        spsUrl: spsUrl
      }).then(function () {
        driverState.ready = true;
        /**
         * Emits event when printer is ready for usage or programitcal interaction.
         * For example you cannot send {@link "7Shop.Print"."7S:Peripherals.UpdatePrinterState"} before ready event.
         *
         * @event module:"7Shop.Print"."Peripherals.PrinterReady"
         */
        $rootScope.$emit('7S:Peripherals.PrinterReady', {
          driverState: driverState,
          selectedPrinter: printer
        });
      }).catch(function (err) {
        $log.error('[7Shop.Print] Fail init of print service driver', {
          ...errorParser.parseUpstream(err),
          selectedPrinter: printer
        });
      });
    });
  }

  function setSpsUrl() {
    var ip = $window.localStorage.getItem('settings.printServiceIp');
    spsUrl = ip ? 'http://' + ip + ':7007/' : NabENV.settings.printService.spsUrl;
  }

  function detectPrintService() {
    var deferred = $q.defer();

    if ($window.navigator.userAgent.toLowerCase().indexOf('android') >= 0) {
      deferred.resolve('nps'); // Change to 'android' when needed
    } else if (Modules.isModuleEnabled('externalPrinter')) {
      driverState.type = 'external_printer';
      deferred.resolve('external_printer');
    } else if (GravitySettings.getModuleDataKeyValue('sps', 'enabled')) {
      setSpsUrl();
      $http.get(spsUrl, { withCredentials: false })
        .then(function (response) {
          detectedPrinters = response.data.printers;
          driverState.type = 'sps';
          deferred.resolve('sps');
        }, function () {
          driverState.type = 'nps';
          deferred.resolve('nps');
        });
    } else {
      driverState.type = 'nps';
      deferred.resolve('nps');
    }

    return deferred.promise;
  }

  /**
   * Get print driver
   * @returns {Object}
   * @memberOf ShopPrintService
   */
  function getDriver() {
    return driver;
  }

  /**
   * Set print driver
   * @param {Object} data
   * @memberOf ShopPrintService
   */
  function setDriver(data) {
    driver = data;
    setActivePrinter();
    driverState.status = PeripheralsSvc.getPeripheral('PRINTER');
    Widgets.registerWidget({
      name: 'sPrinterStatus',
      source: [{
        type: 'ANGULARJS_COMPONENT',
        element: {
          tag: '<s-printer-status></s-printer-status>'
        }
      }],
      positions: [
        {
          id: 'footerRight'
        }
      ]
    });
  }

  /**
   * @param {Object} info
   * @param {String} info.type
   * @param {String} info.action
   * @param {String} info.productId
   * @param {String} info.npsJobName
   * @param {String} info.layoutName
   * @param {String} info.layout
   * @param {Object} data
   * @param {Object} additionalData
   * @param {String} printerId
   * @memberOf ShopPrintService
   */
  function printJob(info, data, additionalData, printerId) {
    var type = info.type;
    var action = info.action || '';
    var productId = info.productId || '';
    var npsJobName = info.npsJobName || type;
    var spsProductEnabled = true;
    var externalEnabled = Modules.isModuleEnabled('externalPrinter');
    var doFake = redirectToFakePrinter(type + action);
    var additionalOptions = {};
    var values;
    var debugData = {
      type: type,
      action: action,
      product_displayid: productId,
      layoutName: info.layoutName,
      layout: info.layout,
      data: data
    };
    var onCatch = function (err) {
      const parsedErr = errorParser.parse(err);
      const error = {
        message: parsedErr.code === 429
          ? $translate.instant('printer_queue_limit_reached')
          : $translate.instant('notifications.print_not_finished'),
        ...err
      };

      $log.error('[7Shop.Print] Failed to print', {
        upstream_code: parsedErr.code,
        upstream_message: parsedErr.message,
        code: 'S_PRINT_JOB_FAILED',
        ...debugData
      });

      return $q.reject(error);
    };
    var onSuccess = function (response) {
      if (response.status && response.status !== 200) { // This is possible for NPS
        return onCatch(response);
      }
      $log.info('[7Shop.Print] Successfully printed.', debugData);
      return $q.resolve(response);
    };

    if (doFake) {
      return $q.resolve();
    }

    if (productId) {
      spsProductEnabled = GravitySettings.getModuleDataKeyValue('module.' + productId, 'spsEnabled');
      values = GravitySettings.getByKey('module.' + productId + '.print');
      if (values) {
        angular.forEach(values, function (value, key) {
          additionalOptions[key] = value;
        });
      }
    }

    if ((driverState.type === 'sps' && spsProductEnabled) || externalEnabled) {
      return getDriver().printJob(
        {
          layoutName: info.layoutName || (type + action),
          nameContainingProduct: type + productId + action,
          layout: info.layout
        },
        data,
        additionalData,
        additionalOptions
      ).then(onSuccess).catch(onCatch);
    }

    return printServiceApi.printJob(
      {
        name: npsJobName,
        action
      },
      data,
      printerId,
      additionalData,
      additionalOptions
    ).then(onSuccess).catch(onCatch);
  }

  /**
   * @param {Object} params
   */
  function getPrinterStatus(params) {
    let spsProductEnabled = true;
    const externalEnabled = Modules.isModuleEnabled('externalPrinter');
    var doFake;
    const skipCheckingPrinterStatus = GravitySettings.getModuleDataKeyValue('print', 'skipCheckingPrinterStatus');

    if (skipCheckingPrinterStatus) {
      return $q.resolve(true);
    }

    params = params || {};
    defaults(params, { productId: false, type: '', action: '' });

    doFake = redirectToFakePrinter(params.type + params.action);

    if (doFake) {
      return $q.resolve();
    }

    if (params.productId && params.productId !== 'Voucher') {
      spsProductEnabled = GravitySettings.getModuleDataKeyValue('module.' + params.productId, 'spsEnabled');
    }

    if ((driverState.type === 'sps' && spsProductEnabled) || externalEnabled) {
      return getDriver().getStatus();
    }

    return printServiceApi.getStatus();
  }

  function redirectToFakePrinter(action) {
    var blacklist = GravitySettings.getModuleDataKeyValue('print', 'redirectToFakePrinter') || [];
    var i = 0;
    var found;
    var re;

    for (i; i < blacklist.length; i += 1) {
      re = new RegExp(blacklist[i].pattern, blacklist[i].flags);
      found = re.test(action);
      if (found) {
        return true;
      }
    }

    return false;
  }

  function getSupportedPrinters() {
    return getDriver().getSupportedPrinters();
  }

  function printA4(url) {
    const options = '_blank';
    const params = 'fullscreen=yes,scrollbars=yes,resizable=yes,status=yes,location=yes,toolbar=yes,'
        + 'menubar=yes,width=0,height=0,left=-1000,top=-1000';
    $window.open(url, options, params);
  }

  return {
    init,
    getDriver,
    setDriver,
    detectPrintService,
    driverState,
    printJob,
    getPrinterStatus,
    getSupportedPrinters,
    printA4
  };
}

export default ShopPrintService;
