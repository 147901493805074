(function () {
  angular.module('7Shop.Core')
    .service('PlayersService', PlayersService);

  /**
   * @class PlayersService
   * @memberOf module:"7Shop.Core"
   */
  function PlayersService(
    $http,
    $q,
    $log,
    companyService,
    SevenRoutes,
    ShopPrintService,
    CommonService,
    Upload
  ) {
    var config = {
      headers: {
        'HTTP-X-SEVEN-CLUB-UUID': companyService.data.uuid
      }
    };

    return {
      playerData: {
        address: '',
        acceptTerms: true,
        birthDate: '',
        country: '',
        documentId: null,
        email: '',
        firstName: '',
        lastName: '',
        taxIdentifier: '',
        timezone: ''
      },

      commonData: {
        countries: [],
        timezones: []
      },

      /**
       * Reset player data
       *
       * @memberOf module:"7Shop.Core".PlayersService
       * @param skip {String} - Property name which doesn't get reset
       */
      clearData: function (skip) {
        var playerData = this.playerData;

        Object.keys(playerData).forEach(function (member) {
          if (member === 'acceptTerms') {
            playerData[member] = true;
            return true;
          }

          if (skip && skip === member) {
            return false;
          }

          playerData[member] = '';
          return true;
        });
      },

      uploadVerificationDoc: function (doc) {
        return Upload.upload({
          url: SevenRoutes.mainUrl + '/shop/player/register-document.json',
          data: { document: doc }
        });
      },

      register: function (data) {
        data.acceptTerms = true;
        return $http.post(
          SevenRoutes.mainUrl + '/shop/player/register.json',
          data,
          config
        );
      },

      searchPlayer: function () {
        return $http.get(SevenRoutes.mainUrl + '/shop/player/' + this.playerData.email
          + '/' + companyService.data.uuid).then(
          function (response) {
            var playerData = this.playerData;

            // Populate player data
            Object.keys(response.data).forEach(function (i) {
              playerData[i] = response.data[i];
            });

            return response;
          }.bind(this),
          function (response) {
            // Reset player data if there was an error
            // Email is search parameter so it's not reset
            this.clearData('email');
            return response;
          }.bind(this)
        );
      },

      searchPlayerByReference: function () {
        return $http.get(SevenRoutes.mainUrl + '/users/player-management/players/player/reference-number/' + this.playerData.email)
          .then(
            function (response) {
              var playerData = this.playerData;

              // Populate player data
              Object.keys(response.data).forEach(function (i) {
                playerData[i] = response.data[i];
              });

              return response;
            }.bind(this),
            function (response) {
              // Reset player data if there was an error
              // Email is search parameter so it's not reset
              this.clearData('email');
              return response;
            }.bind(this)
          );
      },

      resetPassword: function () {
        return $http.get(SevenRoutes.mainUrl + '/shop/player/reset-password/'
          + this.playerData.username + '/' + companyService.data.uuid);
      },

      /**
       * Print player username and password
       * @memberOf module:"7Shop.Core".PlayersService
       * @param password {String}
       */
      printPlayerCredentials: function (password) {
        var userDataForPrint = {
          username: angular.copy(this.playerData.email),
          password: password
        };

        ShopPrintService.printJob({ type: 'userInfo' }, userDataForPrint)
          .catch(function (err) {
            $log.warn('[7Shop.Player] Failed to print userInfo', err);
          });
      },

      getRegistrationCommonData: function () {
        var deferred = $q.defer();

        var promises = [
          CommonService.getCountries(),
          CommonService.getTimezones()
        ];

        $q.all(promises)
          .then(function (response) {
            this.commonData.countries = response[0].data;
            this.commonData.timezones = response[1].data;
            deferred.resolve();
          }.bind(this)).catch((err) => {
            deferred.reject(err);
          });

        return deferred.promise;
      },

      getRegistrationForm: function (companyUuid) {
        return $http({
          url: SevenRoutes.mainUrl + '/form/shop_register_player/' + companyUuid + '.json',
          method: 'GET'
        }, config).then(function (response) {
          return response.data;
        });
      }
    };
  }
})();
