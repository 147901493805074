/**
   *
   * @constructor customerCards
   */
/* @ngInject */
function customerCards(
  $http,
  $log,
  NabENV,
  errorParser,
  companyService
) {
  let customerCardsData = null;

  const setCustomerCardsData = (data) => {
    customerCardsData = data;
  };

  const getCustomerCardsData = () => (customerCardsData);

  const fetchActiveCustomerCards = () => {
    const companyUuid = companyService.getUuid();
    const query = 'channel=SHOP&status=active';
    const customerCardsUrl = `${NabENV.api.customerCards}/tenants/${companyUuid}/customerCards?${query}`;

    $http
      .get(customerCardsUrl)
      .then((customerCardsResponse) => {
        setCustomerCardsData(customerCardsResponse.data);
      })
      .catch((err) => {
        $log.error('[7Shop.Bootstrap] Failed to fetch customer cards', {
          ...errorParser.parseUpstream(err),
          code: 'S_CUSTOMER_CARDS_FETCH_FAILED'
        });
      });
  };

  const init = () => {
    fetchActiveCustomerCards();
  };

  return {
    fetchActiveCustomerCards,
    setCustomerCardsData,
    getCustomerCardsData,
    init
  };
}

export default customerCards;
