import { property } from 'underscore';


/**
 * @description It shows widget. Currently if can only embed iframe.
 */

/* @ngInject */
function sWidgetInclude() {
  return {
    template: '<div class="h-100 w-100"></div>',
    scope: {
      iframeId: '<',
      iframeSrc: '<',
      iframeOnDestroyCb: '&',
      iframeConfig: '<'
    },
    controller: /* @ngInject */ function ($scope, $element) {
      const resizeObserver = new ResizeObserver(() => {
        positionIframe();
      });
      const iframe = getIframe();
      showFrame();
      resizeObserver.observe($element[0]);
      function getFrameId() {
        return property(['frameId'])($scope.iframeConfig) || $scope.iframeId;
      }

      function getIframe() {
        const frame = angular.element('iframe#' + getFrameId()).first();
        return !frame.length ? false : frame;
      }

      function positionIframe() {
        var position = $element[0].getBoundingClientRect();

        iframe.css({
          position: 'fixed',
          left: position.left,
          top: position.top,
          height: position.height,
          width: position.width
        });
      }

      function showFrame() {
        iframe.css({
          visibility: 'visible'
        });
      }

      $scope.$on('$destroy', function () {
        $scope.iframeOnDestroyCb({
          data: angular.copy({
            iframeId: getFrameId(),
            iframeSrc: $scope.iframeSrc
          })
        });
        resizeObserver.disconnect();
        iframe.css({ visibility: 'hidden' });
      });
    }
  };
}

export default sWidgetInclude;
