import translate from 'angular-translate';
import '@nsoft/seven-ng-lib/dist/seven-ng-lib';
import ConnectionCheckerService from './connectionCheckerService';
import PeripheralsModule from '../../peripherals';
/**
 * @module "7Shop.ConnectionChecker"
 */
const moduleName = angular.module('7Shop.ConnectionChecker', [
  translate, // ngLib does not have this as dep and it should have :(
  'ngLib',
  PeripheralsModule
])
  .service('ConnectionChecker', ConnectionCheckerService)
  .run(function (ConnectionChecker) {
    ConnectionChecker.init();
  }).name;

export default moduleName;
