(function () {
  window._7Loader.setConfig({
    blackList: [
      'transactions/debit/pending', // checking pending transactions
      '7platform.com/shop/tickets/request',
      'tickets.json',
      'http://127.0.0.1:5051', // device connection checker
      'http://127.0.0.1:8080', // print service
      'http://127.0.0.1:7007', // seven print service
      'http://127.0.0.1:8083', // fiscal service
      'http://127.0.0.1:8070', // local pub sub
      '.html', // html files load
      ':8008', // SCM port
      'distribution-2.neosoft.ba', // sportbook SCM router
      'sportbook-router', // sportbook SCM router
      'prematchOffer/matches', // matches requests
      'sentry.nsoft.ba',
      '/events.json', // NGS products results
      'direct-deposit', // Direct acc deposit action - started manually
      'device/ping', // Route for device availability
      'https://f2e123fb1ef14dd49a33eb6764226c45@sentry.io/168263',
      'v1/shop/notifications', // blacklist notification api
      'https://f2e123fb1ef14dd49a33eb6764226c45@sentry.io/168263', // Sentry
      'http://127.0.0.1:8081/device_profiles', // Get installed devices
      'http://127.0.0.1:8081/device_power',
      'http://127.0.0.1:8081', // Get device data
      'socket.io',
      'iam/api/v1/authentication/regenerate',
      'api/scm/re-publish.json',
      'cash-register/transfers',
      'cash-register/totals',
      'wallet/location-payment/totals',
      'google-analytics',
      'exchage-rates/nbp/A/eur',
      '8071/setState',
      '8071/publish',
      '/SportsbookSM/check.json',
      '/exchange-rates/nbp/A/eur',
      '/shop/config'
    ],
    preventActions: false,
    safeTimerDelay: 10000 // 10 sec
  });
})();
