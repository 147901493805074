/**
 * @class userService
 * @memberOf module:"7Shop.User"
 */
/* @ngInject */
function userService(
  $window,
  $rootScope,
  $filter,
  $log,
  NabNotifications,
  nabTrans,
  HooksManager,
  nabMessaging,
  UserApiService,
  errorParser
) {
  var self = this;
  var loginData = {};
  var logoutUser = function () {
    NabNotifications.show({
      message: nabTrans.translate('notifications.logout', {}, true),
      type: 'warning action',
      delay: false,
      focus: true,
      actions: false,
      showDefaultAction: false
    });

    /**
     * Emits event when user logs out
     *
     * @event "7S:User.LoggingOut"
     * @memberOf module:"7Shop.User"
     * @deprecated Use {@link module:"7Shop.User".event:"7S:User.LogoutSucceeded"}
     */
    $rootScope.$emit('7S:User.LoggingOut', {});
    /**
     * Emits event when user logs out
     *
     * @event "7S:User.LogoutSucceeded"
     * @memberOf module:"7Shop.User"
     */
    $rootScope.$emit('7S:User.LogoutSucceeded', {});
    UserApiService.logOut().finally(function () {
      // remove token from session storage so you can logout
      sessionStorage.clear();
      $window.location.reload(true);
    });
  };

  var ROLE_SERVICE_PROVIDER = 'SevenShop:';

  var showPreLogoutNotification = function (messageKey, showDefaultAction) {
    var actionString = nabTrans.translate('actions.ok', {}, true);
    var action = [{
      callback: function () {
        logoutUser();
      },
      text: actionString,
      key: 2,
      info: '2 - ' + actionString,
      keyCode: [50, 98],
      noPermission: true
    }];

    NabNotifications.show({
      message: nabTrans.translate(messageKey, {}, true),
      type: 'warning action',
      delay: false,
      focus: true,
      actions: action,
      showDefaultAction: showDefaultAction || false
    });
  };

  function onDeviceReinstall() {
    NabNotifications.show({
      message: nabTrans.translate('notifications.device_reinstalled', {}, true),
      type: 'warning action',
      delay: false,
      focus: true,
      actions: [{
        callback: function () {
          logout();
        },
        text: nabTrans.translate('actions.ok', {}, true),
        key: 1,
        info: '1 - ' + nabTrans.translate('actions.ok', {}, true),
        keyCode: [49, 35, 97],
        noPermission: true
      }],
      showDefaultAction: false
    });
  }

  $rootScope.$on('$http:unauthorizedRequest', function (event, data) {
    if (data.code === 17006) {
      // on token invalidation show notification to user
      onDeviceReinstall();
    }
  });

  // on token invalidation show notification to user
  nabMessaging.subscribe('device:tokenInvalidated', onDeviceReinstall);
  // // on device uninstall remove device data
  nabMessaging.subscribe('NCM:uninstall', onDeviceReinstall);

  nabMessaging.subscribe('NCM:device', function (event, data) {
    data.forEach(function (deviceData) {
      if (deviceData.type === 'deviceStatus' && !deviceData.data.installed) {
        onDeviceReinstall();
      }
    });
  });

  nabMessaging.subscribe('NCM:disabledUser', function () {
    // TODO move this trans to some kind of initialization!
    showPreLogoutNotification('user.disabled_user_message');
  });

  nabMessaging.subscribe('Operator.RoleChanged', function () {
    showPreLogoutNotification('user.changed_permissions_message');
  });

  nabMessaging.subscribe('NCM:betshopRolesChanged', function () {
    showPreLogoutNotification('user.changed_permissions_message');
  });

  function logout() {
    HooksManager.run('BeforeLogout', { logout: logoutUser }).then(() => {
      logoutUser();
    }).catch((err) => {
      $log.warn(`[7Shop.User] Logout error: ${errorParser.parseMessage(err)}`, {
        code: 'S_LOGOUT_ERROR',
        ...errorParser.parseUpstream(err)
      });
    });
  }

  /**
   * Logout user
   *
   * @event "7S:User.Logout"
   * @memberOf module:"7Shop.User"
   */
  $rootScope.$on('7S:User.Logout', function () {
    if (!self.state.loggedIn) {
      return;
    }

    logout();
  });

  this.state = {
    loggedIn: false
  };

  /**
   * @memberOf module:"7Shop.User".userService
   *
   * @returns {SevenOperator}
   */
  this.getUserData = function () {
    return loginData.user;
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.setData = function (data) {
    loginData = data;
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.getData = function () {
    return loginData;
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.logout = logout;

  /**
   * Check does user have permission/role
   *
   * @memberOf module:"7Shop.User".userService
   * @param role
   * @return {Boolean}
   */
  this.hasRole = function (role) {
    return loginData.roles.indexOf(ROLE_SERVICE_PROVIDER + role) >= 0;
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.login = function (params) {
    return UserApiService.login(params).then(function (response) {
      if (response.headers('Access-Token')) {
        sessionStorage.shopToken = response.headers('Access-Token');
      }

      localStorage.setItem(
        'shop.refreshToken',
        response.headers('X-NSFT-IAM-REFRESH-TOKEN')
      );

      self.setData(response.data);
      self.state.loggedIn = true;

      return response;
    });
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.checkSession = function (token, url, appName, betshopUuid) {
    return UserApiService.checkSession(token, url, appName, betshopUuid).then(function (response) {
      if (response.headers('Access-Token')) {
        sessionStorage.shopToken = response.headers('Access-Token');
      }

      localStorage.setItem(
        'shop.refreshToken',
        response.headers('X-NSFT-IAM-REFRESH-TOKEN')
      );

      self.setData(response.data);
      self.state.loggedIn = true;

      return response;
    });
  };

  /**
   * Check does user have a permission to make some action in module
   * @memberOf module:"7Shop.User".userService
   * @param {String} moduleId
   * @param {String} actionId
   * @returns {boolean}
   */
  this.hasSevenPermission = function (moduleId, actionId) {
    var module = $filter('filter')(this.getData().clientAppModules, { id: moduleId }, true);
    var i;
    if (module.length) {
      for (i = 0; i < module[0].actions.length; i += 1) {
        if (module[0].actions[i].id === actionId) {
          return true;
        }
      }
    }

    return false;
  };
}

export default userService;
