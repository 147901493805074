import './key-events-directive.scss';

/**
* @class keyEvents
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function keyEvents(
  $document,
  $log,
  $window,
  NabNotifications,
  ScanSvc,
  KeyEventsSvc,
  OverlaySvc,
  barcodeService
) {
  return {
    priority: 500,
    restrict: 'A',
    link: function () {
      var scanningFinished;

      var onKeyDown = function (e) {
        $log.debug('[7Shop.Common] keyEvents keydown', {
          keyCode: e.keyCode
        });
        // if space or we are already in scan mode,
        // process with scan event handler
        if (ScanSvc.inScanMode()
          || ScanSvc.goingIntoScan(e)) {
          if (OverlaySvc.isOpen()) OverlaySvc.close();

          if (ScanSvc.goingIntoScan(e)) {
            e.stopImmediatePropagation();
            // we need moove focus out of any input (or any other eleent export for body) to body
            // so we don't enter codes from scaner
            if ($window.document.activeElement?.tagName.toLocaleLowerCase() !== 'body') {
              $window.document.activeElement.blur();
            }
            // in case of space just activate scan mode
            ScanSvc.toggleScanMode(true);
            $log.debug('[7Shop.Common] keyEvents scan mode set to true', {
              keyCode: e.keyCode
            });
          } else {
            $log.debug('[7Shop.Common] keyEvents forming barcode from keydown', {
              keyCode: e.keyCode
            });
            // when scanning finishes it will return true
            scanningFinished = ScanSvc.isScanModeOver(e);
            if (!scanningFinished) {
              // this is a hack to fix race condition when scan is started from iframe,
              // iframe postMessage can be late so we need to slow down keydowns processing from main frame
              if (e.gravityGatewayEvent) {
                barcodeService.formCode(e);
              } else {
                // eslint-disable-next-line angular/timeout-service
                setTimeout(() => barcodeService.formCode(e));
              }
            } else {
              // eslint-disable-next-line angular/timeout-service
              setTimeout(() => {
                $log.debug('[7Shop.Common] keyEvents scan finished or disabled', {
                  isScanningFinished: scanningFinished,
                  keyCode: e.keyCode
                });
                // check code,
                barcodeService.checkCode();
                // now reset
                barcodeService.resetCode();
                $log.debug('[7Shop.Common] keyEvents scan finished and disabled');
              });
            }

            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
          }
          // always stop propagation when going or already in scann mode
          e.stopPropagation();
        } else {
          // prevent any action if
          // "warning action || success action" notification is open
          if (NabNotifications.current
            && ['success action', 'warning action'].indexOf(NabNotifications.current.config.type) > -1) {
            $log.debug('[7Shop.Common] Preventing keydown since notification is opened.', {
              keyCode: e.keyCode
            });
            return;
          }
          // propagate keydown down to app only if scan mode is not active
          KeyEventsSvc.processKeyEvent(e);
        }
      };

      // listen for all keydowns trigered in app
      $document
        .on('keydown', onKeyDown);
    }
  };
}

export default keyEvents;
