/**
* @class CloudflareService
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function CloudflareService(GravitySettings) {
  const routes = ['7platform.com', 'nsoft.com', 'neosoft.ba', '7Platform.net'];
  const cloudflareList = [
    {
      search: 'https://services.7platform.com',
      replaceWith: 'https://menhir.gb.nsoftcdn.com'
    },
    {
      search: 'https://ngs.7platform.com',
      replaceWith: 'https://games.gb.nsoftcdn.com'
    },
    {
      search: 'https://gravity-service.7platform.com',
      replaceWith: 'https://gravity.de.nsoftcdn.com'
    },
    {
      search: 'https://cm.7platform.com:8008',
      replaceWith: 'https://scm.gb.nsoftcdn.com:8443'
    },
    {
      search: 'https://scm-ro2.7platform.com:8008',
      replaceWith: 'https://scm.ro-2.nsoftcdn.com:8443'
    },
    {
      search: 'https://scm-ro.7platform.com:8008',
      replaceWith: 'https://scm.ro.nsoftcdn.com:8443'
    },
    {
      search: 'https://scm-de.7platform.com:8008',
      replaceWith: 'https://scm.de.nsoftcdn.com:8443'
    },
    {
      search: 'https://cm.7platform.com:8008',
      replaceWith: 'https://scm.gb.nsoftcdn.com:8443'
    },
    {
      search: 'https://cm3.7platform.com:8008',
      replaceWith: 'https://scm-3.gb.nsoftcdn.com:8443'
    },
    {
      search: 'https://cm-rs2.7platform.com:8008',
      replaceWith: 'https://scm.rs-2.nsoftcdn.com:8443'
    },
    {
      search: 'https://cm-rs.7platform.com:8008',
      replaceWith: 'https://scm.rs.nsoftcdn.com:8443'
    },
    {
      search: 'https://ivg-ro.nsoft.com',
      replaceWith: 'https://int-svg.ro.nsoftcdn.com'
    },
    {
      search: 'https://ngs-ro.nsoft.com',
      replaceWith: 'https://games.ro.nsoftcdn.com'
    },
    {
      search: 'https://gcm-fra-1.7platform.com:8008',
      replaceWith: 'https://gcm-1.de.nsoftcdn.com:8443'
    },
    {
      search: 'https://gcm-fra-2.7platform.com:8008',
      replaceWith: 'https://gcm-2.de.nsoftcdn.com:8443'
    },
    {
      search: 'https://gcm-fra-3.7platform.com:8008',
      replaceWith: 'https://gcm-3.de.nsoftcdn.com:8443'
    },
    {
      search: 'https://ngs-de.nsoft.com',
      replaceWith: 'https://games.de.nsoftcdn.com'
    },
    {
      search: 'https://zodiac.7platform.com:8443',
      replaceWith: 'https://zodiac.de.nsoftcdn.com:8443'
    },
    {
      search: 'https://seven-bonus.7platform.com',
      replaceWith: 'https://seven-bonus.gb.nsoftcdn.com'
    },
    {
      search: 'https://bonus-ro2.7platform.com',
      replaceWith: 'https://seven-bonus.ro-2.nsoftcdn.com'
    },
    {
      search: 'https://bonus-ro.7platform.com',
      replaceWith: 'https://seven-bonus.ro.nsoftcdn.com'
    },
    {
      search: 'https://bonus-de.7platform.com',
      replaceWith: 'https://seven-bonus.de.nsoftcdn.com'
    },
    {
      search: 'https://bonus-rs2.7platform.com',
      replaceWith: 'https://seven-bonus.rs-2.nsoftcdn.com'
    },
    {
      search: 'https://prod-bonus-rs.7platform.com',
      replaceWith: 'https://seven-bonus.rs.nsoftcdn.com'
    },
    {
      search: 'https://tc-api.nsoft.com',
      replaceWith: 'https://tc-service.gb.nsoftcdn.com'
    },
    {
      search: 'https://tax-authority-ro.7platform.com',
      replaceWith: 'https://tax-authority-rom.ro.nsoftcdn.com'
    },
    {
      search: 'https://tax-authority-fbih.7platform.com',
      replaceWith: 'https://tax-authority-fbih.de.nsoftcdn.com'
    },
    {
      search: 'https://tax-authority-mne.7platform.com',
      replaceWith: 'https://tax-authority-mne.de.nsoftcdn.com'
    },
    {
      search: 'https://tax-authority-bih.7platform.com',
      replaceWith: 'https://tax-authority-lutrijabih.ba.nsoftcdn.com'
    },
    {
      search: 'https://tax-authority-pol.7platform.com',
      replaceWith: 'https://tax-authority-pol.de.nsoftcdn.com'
    },
    {
      search: 'https://prod-platform-reporting-lb.7platform.com',
      replaceWith: 'https://seven-reporting.gb.nsoftcdn.com'
    },
    {
      search: 'https://cm2.7platform.com:8008',
      replaceWith: 'https://scm-2.gb.nsoftcdn.com:8443'
    },
    {
      search: 'https://services-de.7platform.com',
      replaceWith: 'https://menhir.de.nsoftcdn.com'
    },
    {
      search: 'https://services-ro2.7platform.com',
      replaceWith: 'https://menhir.ro-2.nsoftcdn.com'
    },
    {
      search: 'https://services-ro.7platform.com',
      replaceWith: 'https://menhir.ro.nsoftcdn.com'
    },
    {
      search: 'https://services-rs2.7platform.com',
      replaceWith: 'https://menhir.rs-2.nsoftcdn.com'
    },
    {
      search: 'https://services-rs.7platform.com',
      replaceWith: 'https://menhir.rs.nsoftcdn.com'
    },
    {
      search: 'https://mule.7platform.com',
      replaceWith: 'https://mule.de.nsoftcdn.com'
    },
    {
      search: 'https://iam-am.7platform.com',
      replaceWith: 'https://seven-iam.de.nsoftcdn.com'
    },
    {
      search: 'https://accounts.7platform.com',
      replaceWith: 'https://seven-accounts.de.nsoftcdn.com'
    }
  ];

  const generateV2CloudflareUrl = function (url) {
    let cloudflareUrlv2 = url;
    cloudflareList.forEach((list) => {
      if (list.search.indexOf(url) !== -1) {
        cloudflareUrlv2 = url.replace(list.search, list.replaceWith);
      }
    });
    return cloudflareUrlv2;
  };

  this.generateCloudflareUrl = function (url) {
    var cloudflareEnabled = GravitySettings.getModuleDataKeyValue('config', 'cloudflare');
    var cloudflareEnabledv2 = GravitySettings.getModuleDataKeyValue('config', 'cloudflareEnabledv2');
    var parsedUrl;
    var cloudflareUrl;

    if (!cloudflareEnabled && !cloudflareEnabledv2) return url;

    try {
      parsedUrl = new URL(url);
    } catch (error) {
      return url;
    }

    cloudflareUrl = parsedUrl.origin;

    if (cloudflareEnabledv2) {
      cloudflareUrl = generateV2CloudflareUrl(cloudflareUrl);
    } else {
      for (let i = 0; i < routes.length; i += 1) {
        if (cloudflareUrl.indexOf(routes[i]) !== -1) {
          cloudflareUrl = cloudflareUrl.replace(routes[i], '7platform.info');
          break;
        }
      }

      if ((cloudflareUrl.indexOf('gcm-fra-1.7platform.info') !== -1)// routes like https://gcm-fra-3.7platform.com:8008/get-lb have port changed on cloudflare
      || (cloudflareUrl.indexOf('gcm-fra-2.7platform.info') !== -1)
      || (cloudflareUrl.indexOf('gcm-fra-3.7platform.info') !== -1)
      || (cloudflareUrl.indexOf('cm.7platform.info') !== -1)
      || (cloudflareUrl.indexOf('cm2.7platform.info') !== -1)
      || (cloudflareUrl.indexOf('cm3.7platform.info') !== -1)) {
        cloudflareUrl = cloudflareUrl.replace(':8008', ':8443');
      }

      if (cloudflareUrl.indexOf('sportbook-public-api') !== -1) {
        cloudflareUrl = cloudflareUrl.replace('sportbook-public-api', 'sportsbook-public-api');
      } else if (cloudflareUrl.indexOf('prod-platform-reporting-lb') !== -1) {
        cloudflareUrl = cloudflareUrl.replace('prod-platform-reporting-lb', 'reporting-api');
      }
    }

    return cloudflareUrl + parsedUrl.pathname + parsedUrl.search;
  };
}

export default CloudflareService;
