/* @ngInject */
export default function LogDecorator(
  $delegate,
  $injector,
  GravitySettings,
  $window
) {
  const logLevels = {
    error: 1,
    warn: 2,
    info: 3,
    debug: 4
  };
  const NabENV = $injector.get('NabENV');
  const getLogLevel = () => {
    const url = new URL($window.location.href);
    const logLevel = logLevels[url.searchParams.get('logLevel')]
        || logLevels[localStorage.getItem('settings.logLevel')]
        || logLevels[GravitySettings.getModuleDataKeyValue('application', 'logLevel')]
        || 'warn';
    return logLevel;
  };

  const sendMessage = (params, level) => {
    const UserService = $injector.get('userService');
    const LocalData = $injector.get('LocalData');
    const logService = $injector.get('logService');
    const [message, ...rest] = params;
    let restParams;
    const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
    const localTime = (new Date(Date.now() - timeZoneOffset));

    if (rest?.length && rest[0]) {
      // Destructure every property and send it separately
      ({ ...restParams } = rest[0]);
    }

    const log = {
      message,
      level,
      appName: '7Shop',
      channel: '7shop',
      timestamp: localTime.toISOString(),
      tenant_id: LocalData.betshop?.companyUuid,
      tenant_name: LocalData.betshop?.companyName,
      device_id: LocalData.device?.uuid,
      device_name: LocalData.device.name,
      betshop_id: LocalData.betshop?.uuid,
      betshop_name: LocalData.betshop?.name,
      operater_id: UserService.getData()?.user?.uuid,
      ...restParams
    };
    logService.logs.push(log);
    if (logService.logs.length >= NabENV.graylog.messageLimit) {
      logService.sendLogsToGraylog();
    }
  };

  const swapLog = function (originalFn, level) {
    return function () {
      if (getLogLevel() >= logLevels[level]) {
        sendMessage([...arguments], level);
      }
      originalFn.apply(null, arguments);
    };
  };

  $delegate.error = swapLog($delegate.error, 'error');
  $delegate.warn = swapLog($delegate.warn, 'warn');
  $delegate.info = swapLog($delegate.info, 'info');
  $delegate.debug = swapLog($delegate.debug, 'debug');

  return $delegate;
}
