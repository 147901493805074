(function () {
  angular.module('7Shop.Core')
    .factory('switchView', switchView);

  /**
   * @constructor switchView
   */
  function switchView(
    $state,
    $rootScope,
    SevenGamesSvc,
    OverlaySvc
  ) {
    function activateIframe(service) {
      service.productId = service.id;

      return $state.go('app.home.games', { gameId: service.id })
        .then(function () {
          $rootScope.$emit(
            '7S:Slave.Unmute',
            {
              productId: service.productId
            }
          );

          $rootScope.$emit(
            '7S:Slave.Awake',
            {
              productId: service.productId
            }
          );
          $rootScope.$emit(
            '7S:UI.Show',
            {
              productId: service.productId,
              name: ['BettingArea']
            }
          );
          $rootScope.$emit(
            '7S:Slave.Shown',
            service
          );
          return true;
        });
    }

    return {

      /**
           * Activate product and its services
           *
           * @memberOf switchView
           * @param {SevenGameSvc | string} service
           */
      selectService: function (service) {
        if (angular.isString(service)) {
          service = SevenGamesSvc.getGame(service);
        }

        SevenGamesSvc.setActiveGame(service.id);
        $rootScope.$emit('7S:Header:UpdateGames', service);

        return activateIframe(service);
      },

      /**
           * Check is product view active
           *
           * @memberOf switchView
           * @param {String} productId
           * @return {boolean}
           */
      isServiceActive: function (productId) {
        return $state.is('app.home.games', { gameId: productId });
      },

      /**
           * @memberOf switchView
           */
      back: function () {
        var game = SevenGamesSvc.activeGame;
        if (game) {
          this.selectService(game.id);
        } else {
          OverlaySvc.open('menu');
        }
      }

    };
  }
})();
