(function () {
  angular.module('7Shop.Common')
    .factory('ScanSvc', ScanSvc);

  /**
   *
   * @constructor ScanSvc
   */
  function ScanSvc(
    $timeout,
    KeyMapper
  ) {
    var inScanMode = false;
    var secureTimerOff = null;
    var threshhold = 1500;
    var scanStarted = null;

    return {

      /**
       * Check are we in scan mode.
       * It means that something is currently scanned by scanner
       *
       * @memberOf ScanSvc
       * @returns {boolean}
       */
      inScanMode: function () {
        return inScanMode;
      },

      /**
       * Check is scanner giving signal to go into scan mode
       *
       * @memberOf ScanSvc
       * @param {KeyboardEvent} e
       * @return {boolean}
       */
      goingIntoScan: function (e) {
        return KeyMapper.space.key === e.keyCode;
      },

      /**
       * Turn on/off scan mode
       * @memberOf ScanSvc
       * @param mode
       */
      toggleScanMode: function (mode) {
        // if starting scan mode,
        // set safe timer so we don't get stuck in safe mode
        // (how is this possible? ask dino?)
        if (mode) {
          scanStarted = new Date().getTime();
          secureTimerOff = $timeout(() => {
            this.toggleScanMode(false);
            scanStarted = null;
          }, 2000, false);
        }

        inScanMode = mode;
      },

      /**
       * Is scan over. If last key is enter scan is over
       *
       * @memberOf ScanSvc
       * @param {KeyboardEvent} e
       * @return {String | Boolean}
       */
      isScanModeOver: function (e) {
        // if enter is hit it is end
        // of scan mode, clear stuff
        // and send ticket id to to check service
        if (e.keyCode === KeyMapper.enter.key) {
          this.toggleScanMode(false);
          $timeout.cancel(secureTimerOff);
          return true;
        }

        return false;
      },

      /**
       * Is scan process not getting started and/or finished.
       *
       * @memberOf ScanSvc
       * @param {KeyboardEvent} e
       * @return {boolean}
       */
      finished: function (e) {
        var timePast = scanStarted ? (new Date().getTime() - scanStarted) > threshhold : true;

        return timePast
          && !this.goingIntoScan(e)
          && !this.inScanMode();
      }
    };
  }
})();
