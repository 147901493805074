import sGamesHelp from './games_help/gamesHelpComponent';
import sGamesHelpFooter from './games_help/helpFooterComponent';
import gameService from './gameService';
/**
 * @module "7Shop.Games"
 */
const GamesModule = angular.module('7Shop.Games', [])
  .component('sGamesHelp', sGamesHelp)
  .component('sGamesHelpFooter', sGamesHelpFooter)
  .service('GameService', gameService)
  .name;

export default GamesModule;
