import angular from 'angular';
import '@nsoft/seven-ng-client-core/dist/seven-ng-client-core';
import commonModule from '../common';

import UserApiService from './userApiService';
import userService from './userService';

/**
 * @module "7Shop.User"
 */
const moduleName = angular.module('7Shop.User', [
  'SevenClient.Config',
  'SevenClientLib',
  commonModule
])
  .service('UserApiService', UserApiService)
  .service('userService', userService)
  .name;

export default moduleName;
