import { findWhere } from 'underscore';

(function () {
  angular.module('7Shop.Core')
    .service('GeneralModulesService', GeneralModulesService);

  function GeneralModulesService(
    $rootScope,
    $state,
    $filter,
    $injector,
    SevenClientCoreSvc,
    nabTrans,
    SevenGamesSvc,
    OverlaySvc,
    switchView,
    Shortcuts,
    Modules
  ) {
    var modules = [];
    var deregister;

    function init() {
      var lazyLoadableModules = Modules.getModulesByPosition('onGeneralModulesInit');
      modules = SevenClientCoreSvc.getGeneralModules();

      modules.push({
        id: 'PrintOptions',
        translation: nabTrans.translate('main.offer_results_print', {}, true)
      });

      modules.forEach(function (module) {
        var doLoad = findWhere(lazyLoadableModules, { id: module.id });
        if (doLoad) {
          Modules.loadModule(module.id);
        }
        $rootScope.$on('7S:KeyPressed:' + module.id, function (e, data) {
          // Alt keys are used as general modules shortcuts
          handleModuleShortcut(data.code);
        });
      });
    }

    function handleModuleShortcut(shortcut) {
      var module = $filter('filter')(
        modules, { shortcut: shortcut.toUpperCase() },
        true
      );

      // If it is not one of pages (modules)
      if (module.length) {
        if (Modules.isModuleEnabled('googleAnalytics')) {
          $injector.get('Analytics').trackEvent('Open Module', 'Shortcut', module[0].id);
        }
        // First close any existing overlay
        OverlaySvc.close();
        if ($state.is('app.home.' + module[0].id)) {
          switchView.selectService(SevenGamesSvc.activeGame);
        } else {
          Modules.loadModule(module[0].id).then(function () {
            if (!$state.includes('app.home.' + module[0].id)) {
              $state.go('app.home.' + module[0].id);
            }
          });
        }
      }
    }

    /**
       * Set shortcuts for active general modules
       * Update shortcuts in allModulesList necessary for main overlay menu
       */
    function setGeneralModulesShortcuts() {
      var generalModulesShortcuts = Shortcuts.getShortcutByCategory('general_module');

      generalModulesShortcuts.forEach(function (generalShortcut) {
        var module = SevenClientCoreSvc.getModule(generalShortcut.action);
        var updatedShortcut;
        if (module) {
          // Set it explicitly to true because if module was deactivated it would
          // set active as false and shortcut would be deactivated even after logout/login
          // because we keep reference on shortcuts from NabENV and NabENV is not updated after logout/login
          // This problem will gone with T37566
          generalShortcut.active = true;
          updatedShortcut = Shortcuts.updateShortcut({
            action: module.id,
            name: nabTrans.translate('main.toggle_with_value', { value: module.translation }, true)
          });

          module.shortcut = updatedShortcut.shortcut;
        } else {
          generalShortcut.active = false;
        }
      });
    }

    deregister = $rootScope.$on('SevenClientCore:ready', function () {
      init();
      setGeneralModulesShortcuts();
      deregister();
    });

    return {
      activeModule: {},
      setActiveModule: function (moduleId) {
        this.activeModule = $filter('filter')(modules, { id: moduleId }, true)[0];
        return this.activeModule;
      }
    };
  }
})();
