/* @ngInject */
function bitwiseFilter() {
  return function (array, value) {
    var outcomesColours = [
      'Red',
      'Green',
      'Blue',
      'Purple',
      'Brown',
      'Yellow',
      'Orange',
      'Black'
    ];
    var valid = [];
    var resolvedColor;
    var colors = '';
    value = parseInt(value, 10);

    array.forEach(function (member) {
      // eslint-disable-next-line no-bitwise, no-restricted-properties
      if (value & Math.pow(2, member)) {
        resolvedColor = member === 0 ? 8 : member;
        valid.push(resolvedColor);
      }
    });

    valid.forEach(function (color) {
      colors += outcomesColours[color - 1] + ' ';
    });

    return colors;
  };
}

export default bitwiseFilter;
