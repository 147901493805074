import jwtDecode from 'jwt-decode';

(function () {
  angular.module('7Shop.Core')
    .factory('TokenRefresh', TokenRefresh);

  /**
     *
     * @constructor TokenRefresh
     */
  function TokenRefresh(
    $http,
    $window,
    $rootScope,
    $interval,
    SevenRoutes,
    nabTrans,
    NabNotifications
  ) {
    var TOKEN_REFRESH_OFFSET_MS = 18000;

    function getTokens() {
      return {
        accessToken: sessionStorage.shopToken,
        refreshToken: localStorage.getItem('shop.refreshToken')
      };
    }

    function setTokens(accessToken, refreshToken) {
      sessionStorage.shopToken = accessToken;
      localStorage.setItem('shop.refreshToken', refreshToken);
      $rootScope.$emit('7S:User.AuthorizationChanged', {
        productId: '*',
        user: {
          auth: {
            accessToken: accessToken,
            refreshToken: refreshToken
          }
        }
      });
    }

    function setTokenRefreshCheck() {
      var tokens = getTokens();
      var tokenRefreshInterval;

      if (!tokens.accessToken || !tokens.refreshToken) return;

      tokenRefreshInterval = getTokenRefreshInterval(tokens.accessToken);

      $interval(function () {
        tokens = getTokens();
        refreshAccessToken(tokens.refreshToken);
      }, tokenRefreshInterval, 0, false);
    }

    function refreshAccessToken(refreshToken) {
      $http({
        url: SevenRoutes.mainUrl + '/iam/api/v1/authentication/regenerate',
        method: 'POST',
        data: {
          refreshToken: refreshToken
        }
      }).then(function (response) {
        var data = response.data;
        setTokens(data.accessToken, data.refreshToken);
      }).catch(function () {
        var actionString = nabTrans.translate('actions.ok', {}, true);
        var action = [{
          callback: function () {
            $rootScope.$emit('7S:User.Logout');
          },
          text: actionString,
          key: 2,
          info: '2 - ' + actionString,
          keyCode: [50, 98],
          noPermission: true
        }];
        NabNotifications.show({
          message: nabTrans.translate('user.session_expired_message', {}, true),
          type: 'warning',
          delay: false,
          actions: action,
          showDefaultAction: false
        });
      });
    }

    function getTokenRefreshInterval(accessToken) {
      var tokenExpiryTime = jwtDecode('Bearer ' + accessToken).exp;
      var currentTime = moment().unix();

      return ((tokenExpiryTime - currentTime) * 1000) - TOKEN_REFRESH_OFFSET_MS;
    }

    return {

      /**
             *
             * @memberOf TokenRefresh
             *
             */
      getTokens: getTokens,

      /**
             * @memberOf TokenRefresh
             */
      setTokenRefreshCheck: setTokenRefreshCheck
    };
  }
}());
