/**
 * @class ConnectionChecker
 * @memberof module:"7Shop.ConnectionChecker"
 */
/* @ngInject */
function ConnectionChecker(
  $q,
  $rootScope,
  PeripheralsSvc,
  nabTrans
) {
  /**
   * @memberof module:"7Shop.ConnectionChecker".ConnectionChecker
   * @type {{valid: boolean, message: string}}
   */
  this.state = {
    valid: false,
    message: ''
  };

  /**
   * @memberof module:"7Shop.ConnectionChecker".ConnectionChecker
   */
  this.init = function () {
    PeripheralsSvc.register({
      id: 'CONNECTION_CHECKER',
      valid: false,
      validate: function () {
        return this.getStatus() ? $q.resolve() : $q.reject({
          message: nabTrans.translate('notifications.check_connection', {}, true)
        });
      }.bind(this)
    });

    /**
     * Listen for message to update connection state.
     * Depending on passed state:
     *  - it will change connection status color in bottom left corner
     *  - it will prevent money actions (payin, payout, cancel) if connection is not ok
     *
     * @event "7S:Connection.UpdateState"
     * @memberOf module:"7Shop.ConnectionChecker".ConnectionChecker
     * @type {Object}
     * @property {Object} data
     * @property {boolean} data.valid - True for connection is ok.
     */
    $rootScope.$on(
      '7S:Connection.UpdateState',
      function (e, eventData) {
        this.updateStatus(eventData.data.valid);
      }.bind(this)
    );
  };

  /**
   *
   * @memberof module:"7Shop.ConnectionChecker".ConnectionChecker
   * @param {boolean} status
   */
  this.updateStatus = function (status) {
    this.state.valid = status;
  };

  /**
   * @memberof module:"7Shop.ConnectionChecker".ConnectionChecker
   */
  this.getStatus = function () {
    return this.state.valid;
  };
}

export default ConnectionChecker;
