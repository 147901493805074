import router from '@uirouter/angularjs';
import translate from 'angular-translate';
import '@nsoft/seven-ng-client-core/src/seven/sevenServices';
import '@nsoft/seven-ng-socket/dist/nab-ng-socket';
import '@nsoft/seven-ng-client-core/dist/seven-ng-client-core';
import '@nsoft/seven-ng-lib/dist/seven-ng-lib';
import sInputSwitchDirective from './directives/sInputSwitch/sInputSwitchDirective';
import sAutoSelectText from './directives/sAutoSelectText';
import modalSvcConfig from './modal/modalConfigProvider';
import modalSvc from './modal/modalOldService';
import sWidgetInclude from './widget/widgetIncludeDirective';
import NotificationsService from './notifications/notificationsService';
import bitwiseFilter from './filter/bitwiseFilter';

/**
  * Common directives, services, filters.
  * @module "7Shop.Common"
  */
angular.module('7Shop.Common', [
  router,
  translate,
  'SevenClientCore',
  'SevenClientLib',
  'ngLib'
]).directive('sInputSwitch', sInputSwitchDirective)
  .directive('sAutoSelectText', sAutoSelectText)
  .directive('sWidgetInclude', sWidgetInclude)
  .service('NabNotifications', NotificationsService)
  .filter('bitwise', bitwiseFilter)
  .provider('modalSvcConfig', modalSvcConfig)
  .factory('modalSvc', modalSvc);
