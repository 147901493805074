import { each, has, isUndefined } from 'underscore';

/**
   * @class GameService
   * @memberof module."7Shop.Core"
   */
/* @ngInject */
function GameService(
  $q,
  $log,
  $rootScope,
  $translate,
  userService,
  companyService,
  TokenRefresh,
  SevenGamesSvc,
  nabTrans,
  currency,
  SevenClientConfig,
  nabMessaging,
  NabNotifications,
  GravityMenus,
  Shortcuts,
  GravitySettings,
  ConfigService,
  SevenRoutes,
  Widgets,
  LocalData,
  ShopEnvironment
) {
  let hideGamesHelp = JSON.parse(localStorage.getItem('settings.hideGamesHelp'));

  const widgets = {
    calculator: {
      show: true
    },
    ticketList: {
      show: true
    },
    gamesHelp: {
      show: isUndefined(hideGamesHelp) ? true : !hideGamesHelp,
      persis: true
    },
    ticketSession: {
      show: true
    }
  };

  function getDataForProductLoad(service) {
    const userData = userService.getData();
    const companyData = companyService.data;
    const deviceData = userData.device;
    const user = userService.getUserData();
    const accountData = service.info;
    const slaveLoadVersion = GravitySettings.getByKey('module.' + service.id).slaveLoadVersion;
    const data = {
      account: {
        linkId: accountData.cpvUuid,
        uuid: accountData.uuid
      },
      backends: { // deprecated
        scm: {
          url: SevenRoutes.cmUrl
        }
      },
      betshop: {
        id: userData.betshop.id,
        uuid: userData.betshop.uuid,
        device: userData.device // deprecated
      },
      channel: 'Retail',
      company: { // deprecated
        currency: companyData.currency, // moved to currency
        datacenter: companyData.datacenter, // deprecated
        id: companyData.id, // deprecated, moved to tenant
        language: companyData.language, // deprecated, moved to locale.iso2
        name: companyData.name, // deprecated, moved to tenant
        uuid: companyData.uuid // deprecated, moved to tenant
      },
      currency: slaveLoadVersion === 1 ? currency.value : { symbol: companyData.currency, virtualSymbol: currency.value },
      device: {
        uuid: deviceData.uuid
      },
      dp: 'Retail', // deprecated
      locale: {
        iso1: ShopEnvironment.getLanguage()
      },
      money: {
        currencyDisplayValue: currency.value, // deprecated, use currency.symbol
        cashStrategy: ConfigService.getCashStrategy()
      },
      product: { // deprecated, moved to account node
        cpvUuid: service.info.cpvUuid,
        translation: service.info.translation, // deprecated
        uuid: service.info.uuid,
        id: service.info.id
      },
      config: service.config, // deprecated
      barcodeScan: {
        treshold: 40,
        regex: {
          pattern: '^[A-Za-z0-9.\\-%]$',
          flags: 'i'
        },
        prefix: 'space'
      },
      sevenConfig: { // deprecated, use account node
        cpvUuid: accountData.cpvUuid,
        uuid: accountData.uuid,
        shortcut: accountData.shortcut,
        id: accountData.id,
        translation: accountData.translation
      },
      tenant: {
        name: companyData.name,
        uuid: companyData.uuid
      },
      tokens: TokenRefresh.getTokens(), // deprecated
      user: {
        auth: {
          accessToken: TokenRefresh.getTokens().accessToken, // deprecated, use token
          token: TokenRefresh.getTokens().accessToken
        },
        email: user.email,
        firstName: user.firstName,
        id: user.id,
        lastName: user.lastName,
        language: user.language, // deprecated
        logged: true, // operator is always logged in when data is emitted to slave
        name: user.username,
        uuid: user.uuid
      },
      deprecated: {
        warn: 'DO NOT USED THIS NODE',
        configRoutes: userData.configRoutes,
        cms: {
          appSettings: GravitySettings.getData()
        },
        device: {
          token: LocalData.device.token
        },
        LocalAppConfig: {
          sevenUrl: SevenRoutes.mainUrl
        },
        cloudMessaging: userData.cloudMessaging,
        translations: $translate.getTranslationTable(),
        clientAppModules: userData.clientAppModules.filter((module) => {
          const moduleConfig = GravitySettings.getByKey('module.' + module.id);
          // QUICK fix, we need to send only games that are in iframe,
          // any game that is in iframe will not have `modules` property,
          // long term this node should be removed and we should provide games in accounts array
          return module.type === 'product' && !moduleConfig.modules;
        })
      }
    };
    return data;
  }

  function boostrapIframeProducts(games, registerIframeModule) {
    var queue = [];
    // let's boot products in iframe
    games.forEach((game) => {
      const productData = {
        productId: game.id,
        data: {
          ticketConfig: {
            color: GravitySettings.getByKey('module.' + game.id).color
          }
        }
      };
      $rootScope.$emit('7S:Widget.SettingsChanged', productData);
      queue.push($q((resolve) => {
        Widgets.getByType(['PRODUCT_HOME'], {
          value: game.id
        }).then((res) => {
          registerIframeModule({
            iframeSrc: res.source[0].url,
            iframeId: game.id,
            iframeConfig: angular.extend(
              res.source[0].config || {},
              {
                snoozOnInit: true
              }
            ),
            context: {
              data: getDataForProductLoad(game)
            }
          }).then(() => resolve())
            .catch(() => resolve());
        }).catch(() => {
          $log.info('[7Shop.Core] Game widget for PRODUCT_HOME not found', {
            produt_displayid: game.id
          });
          resolve();
        });
      }));
    });

    return queue;
  }

  // listen for unhandled ticketUpdate mesasge from NCM and resolve it,
  // this is case when we have product inside iframe but it is using SCM as messaging infrastructure
  nabMessaging.subscribe('NCM:ticketUpdate', function (e, data) {
    var eventData = data.data;
    $rootScope.$emit('7S:Tickets.Update', {
      action: eventData.data.action,
      ticketData: eventData.data
    });
  });

  $rootScope.$on('SevenClientCore:ready', function () {
    var games = SevenGamesSvc.getGames();
    var settings = SevenClientConfig.getSettings().modules.games;
    var megaMenuPriorities = GravityMenus.getMenuAsObjectOfPriorities('megaMenu');
    var failedGames = '';
    var gamesFiltered;

    // Disable reset betslip if there are no active games
    if (!games.length) {
      Shortcuts.disableShortcut('reset_betslip');
    }

    // Filter games and set priority
    gamesFiltered = games.filter(function (module) {
      if (megaMenuPriorities[module.id]) {
        module.shortcutPriority = megaMenuPriorities[module.id];
        return true;
      }
      return false;
    });

    // Sort games by priority
    gamesFiltered.sort(function (a, b) {
      return b.shortcutPriority - a.shortcutPriority;
    });

    gamesFiltered.forEach(function (module, i) {
      // add shortcut key
      module.shortcut = settings.shortcutPrefix + (i + 2);
    });

    if (SevenGamesSvc.failedGames.length) {
      failedGames = '';

      angular.forEach(SevenGamesSvc.failedGames, function (value, key) {
        failedGames += SevenGamesSvc.failedGames.length === (key + 1) ? value.id : value.id + ',';
      });

      NabNotifications.show({
        message: nabTrans.translate('notifications.config_load_failed', {
          games: failedGames
        }, true),
        type: 'warning',
        delay: 3000,
        focus: false,
        actions: false
      });
    }
  });
  /**
       *
       * @param e
       * @param eventData
       * @param eventData.productId
       * @param eventData.data
       * @param eventData.data.shortcuts
       * @param eventData.data.ticketCheckFormatter
       */
  function updateSettings(e, eventData) {
    var dataToUpdate = {};
    each(eventData.data, function (value, key) {
      if (angular.isDefined(value)) {
        dataToUpdate[key] = value;
      }
    });

    SevenGamesSvc.getGame(eventData.productId)
      .updateLocalSettings(dataToUpdate);

    updateWidgetState(e, eventData);
  }

  function updateWidgetState(e, eventData) {
    // update widget state
    $rootScope.$evalAsync(function () {
      each(eventData.data, function (value, key) {
        // if Slave is coming back to Betting Area, enable games help icon
        if (has(widgets, key)) {
          if (key === 'gamesHelp'
              && isUndefined(value.disableInFooter)
              && !isUndefined(widgets[key].disableInFooter)) {
            delete widgets[key].disableInFooter;
          }

          widgets[key] = angular.extend(widgets[key] || {}, value);
        }
      });
    });
  }

  /**
   * Listen for game config update
   *
   * @event 7S:Widget.UpdateProductSettings
   * @deprecated
   * @type {Object}
   * @property {String} action
   * @property {Object} data
   * @property {Object} data.productId
   * @property {Object} data.shortcuts
   * @property {Function} data.ticketCheckFormatter
   *
   */
  $rootScope.$on(
    '7S:Widget.UpdateProductSettings', updateSettings
  );

  $rootScope.$on(
    '7S:Widget.SettingsChanged', updateSettings
  );

  $rootScope.$on(
    '7S:Widget.UpdateSettings', updateWidgetState
  );

  return {
    widgets,

    /**
     *
     * Set new state of games help or if new state not passed just toggle it from
     * previous state
     * @memberof module."7Shop.Core".GameService
     * @param {Boolean} [newValue] - new state
     * @param {Boolean} [persist=true] - should be persist new change
     */
    toggleGamesHelp: function (newValue, persist) {
      newValue = newValue || !this.widgets.gamesHelp.show;
      persist = persist || true;
      // on toggle list
      this.widgets.gamesHelp.show = newValue;
      if (persist) {
        localStorage.setItem('settings.hideGamesHelp', !newValue);
      }

      if (newValue) {
        $rootScope.$emit(
          '7S:UI.Show',
          {
            name: ['GamesHelp'],
            productId: SevenGamesSvc.getActiveGameId()
          }
        );
      }
    },

    setWidgetStateOnGameEnter: function () {
      // when coming to BettingArea, always show all widgets but respect user settings
      // for gamesHelp state
      hideGamesHelp = JSON.parse(localStorage.getItem('settings.hideGamesHelp'));
      updateWidgetState(null, {
        data: {
          calculator: {
            show: true
          },
          ticketList: {
            show: true
          },
          gamesHelp: {
            show: isUndefined(hideGamesHelp) ? true : !hideGamesHelp
          },
          ticketSession: {
            show: true
          }
        }
      });
    },

    /**
           * @memberof GameService
           * @returns {Boolean}
           */
    getDataForProductLoad: getDataForProductLoad,

    /**
           * @memberof GameService
           * @returns {Boolean}
           */
    boostrapIframeProducts: boostrapIframeProducts,

    mergeGravityConfigWithProducts: function (modules) {
      var moduleConfig = {};
      var configLocalOverwrite = {};
      modules.forEach(function (module) {
        if (module.type === 'product') {
          moduleConfig = GravitySettings.getByKey('module.' + module.id);
          // let's give option for DEV to override CMS config,
          // for example DEV wants to set URL to localhost
          configLocalOverwrite = JSON.parse(localStorage.getItem('module.' + module.id));
          if (configLocalOverwrite || moduleConfig) {
            module.local = configLocalOverwrite || moduleConfig;
            if (configLocalOverwrite) {
              // eslint-disable-next-line
                    console.info('Product config loaded from local storage', module.id, configLocalOverwrite);
            }
          }
        }
      });
    }
  };
}

export default GameService;
