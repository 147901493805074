(function () {
  angular.module('7Shop.Core')
    .service('TicketsSessions', TicketsSessions);

  /**
   * @typedef {Object} S_Session
   * @memberOf TicketsSessions
   * @property {Array.<SevenTicket>} tickets
   * @property {number} payment
   * @property {number} payout
   */

  /**
   *
   * @constructor TicketsSessions
   */
  function TicketsSessions(
    $rootScope
  ) {
    var self = this;

    this.sessionsActive = true;

    /**
     * @memberOf TicketsSessions
     * @type {{active: null | S_Session, list: Array.<S_Session>}}
     */
    this.sessions = {
      active: null,
      list: []
    };

    /**
     * @memberOf TicketsSessions
     * @return {S_Session}
     */
    this.startSession = function () {
      var index = this.sessions.list.push({
        tickets: [],
        payment: 0,
        payout: 0
      });

      this.sessions.active = this.sessions.list[index - 1];
      return this.sessions.active;
    };

    /**
     * @memberOf TicketsSessions
     */
    this.endSession = function () {
      this.sessions.list.length = 0;
      this.sessions.active = null;
    };

    /**
     * @memberOf TicketsSessions
     * @return {S_Session}
     */
    this.getActiveSession = function () {
      return this.sessions.active;
    };

    this.activateSessions = function () {
      this.sessionsActive = true;
    };

    this.deactivateSessions = function () {
      this.sessionsActive = false;
    };

    this.endDirtySession = function () {
      if (this.sessions.active.tickets.length) {
        this.endSession();
      }
    };

    this.startNewOrGetActiveSession = function () {
      var activeSession = this.getActiveSession();
      if (activeSession) {
        return activeSession;
      }

      return this.startSession();
    };

    $rootScope.$on(
      '7S:Tickets.AddSuccess',
      function (e, message) {
        var activeSession;
        var existingTicket;
        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();
        existingTicket = activeSession.tickets.filter(function (ticket) {
          return message.ticketData.requestUuid === ticket.requestUuid;
        });

        // Make sure that payin action is idempotent
        if (!existingTicket || !existingTicket.length) {
          activeSession.tickets.push(message.sevenTicket);
          activeSession.payment += parseFloat(message.sevenTicket.payin);
        } else {
          existingTicket[0] = message.sevenTicket;
        }
      }
    );

    $rootScope.$on(
      '7S:Tickets.PayoutSuccess',
      function (e, message) {
        var activeSession;
        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();

        activeSession.tickets.push(message.sevenTicket);

        activeSession.payout += parseFloat(message.sevenTicket.payout);
      }
    );

    $rootScope.$on(
      '7S:Tickets.CashoutSuccess',
      function (e, message) {
        var activeSession;
        const ticket = message.ticket;
        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();

        activeSession.tickets.push(ticket);

        activeSession.payout += parseFloat(ticket.getPayoutAmount());
      }
    );

    $rootScope.$on(
      '7S:Tickets.CancelSuccess',
      function (e, message) {
        var activeSession;
        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();

        activeSession.tickets.push(message.sevenTicket);

        activeSession.payment -= parseFloat(message.sevenTicket.payin);
      }
    );

    $rootScope.$on(
      '7S:Vouchers.AddSuccess',
      function (e, message) {
        var activeSession;
        var existingVoucher;
        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();
        existingVoucher = activeSession.tickets.filter(function (ticket) {
          return message.requestUuid === ticket.requestUuid;
        });

        if (!existingVoucher || !existingVoucher.length) {
          activeSession.tickets.push(message);
          activeSession.payment += parseFloat(message.totalAmount);
        } else {
          existingVoucher[0] = angular.merge(existingVoucher[0], message);
        }
      }
    );

    $rootScope.$on(
      '7S:Vouchers.PayoutSuccess',
      function (e, message) {
        var activeSession;
        var found;

        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();

        found = activeSession.tickets.filter(function (ticket) {
          return (message.id === ticket.id && message.action.toLowerCase() === ticket.action.toLowerCase());
        });

        if (!found[0]) {
          activeSession.tickets.push(message);
        } else {
          found[0].update(message);
        }

        activeSession.payout += parseFloat(message.totalAmount);
      }
    );

    $rootScope.$on(
      '7S:Vouchers.CancelSuccess',
      function (e, message) {
        var activeSession;
        var found;
        if (!self.sessionsActive) return;
        activeSession = self.getActiveSession();

        found = activeSession.tickets.filter(function (ticket) {
          return (message.id === ticket.id && message.action.toLowerCase() === ticket.action.toLowerCase());
        });

        if (!found[0]) {
          activeSession.tickets.push(message);
        } else {
          found[0].update(message);
        }

        activeSession.payment -= parseFloat(message.totalAmount);
      }
    );
  }
})();
