import ConnectionCheckerModule from './modules/connection_checker';
import printModule from './peripherals/print';
import commonModule from './common';
import LoggerModule from './modules/logger';
import GamesModule from './games';
import coreModule from './core';
import timingInterceptor from '../bootstrap/interceptors/timingInterceptor';

angular.module('7Shop', [
  '7Shop.Bootstrap',
  ConnectionCheckerModule,
  printModule,
  commonModule,
  LoggerModule,
  GamesModule,
  coreModule
]).config(function (
  $provide,
  $httpProvider,
  $compileProvider,
  $logProvider,
  $translateProvider,
  $stateProvider,
  $urlServiceProvider,
  $windowProvider,
  ShopEnvironmentProvider
) {
  const $ngWindow = $windowProvider.$get();
  var isDebugMode = ShopEnvironmentProvider.isDebugMode();

  // disable debug see https://docs.angularjs.org/guide/production#disabling-debug-data
  $compileProvider.debugInfoEnabled(isDebugMode);
  $compileProvider.commentDirectivesEnabled(false);
  $compileProvider.cssClassDirectivesEnabled(false);
  $logProvider.debugEnabled(isDebugMode);
  $httpProvider.defaults.headers.common['content-type'] = 'application/json';
  $httpProvider.defaults.withCredentials = true;
  $httpProvider.interceptors.push('sevenInterceptor');
  $httpProvider.interceptors.push('tokenInterceptor');
  $httpProvider.interceptors.push('gravityInterceptor');
  $httpProvider.interceptors.push(timingInterceptor);

  if (!isDebugMode) {
    // prevent right click (inspect) in production
    $ngWindow.addEventListener('contextmenu', (e) => e.preventDefault());
  }

  $provide.decorator('$exceptionHandler', function exceptionHandlerDecroator(
    $delegate,
    $log,
    $injector,
    GravitySettings
  ) {
    // See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
    var angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

    function isEnabled() {
      var sentryCmsConfig = GravitySettings.getModuleDataKeyValue('config', 'sentry');
      return !ShopEnvironmentProvider.isDebugMode()
      && (sentryCmsConfig && sentryCmsConfig.enabled === true);
    }

    return function (ex, cause) {
      var sentry;
      var Modules = $injector.get('Modules');

      if (isEnabled()) {
        import(/* webpackChunkName: "integrations.sentry" */ './integrations/sentry')
          .then((mod) => {
            Modules.getLoader().inject(mod.default)
              .then(function () {
              // this is only ran once wen first error occures
                sentry = $injector.get('sentry');
                sentry.withScope(function (scope) {
                  if (cause) {
                    scope.setExtra('cause', cause);
                  }
                  scope.addEventProcessor(function (event) {
                    var exception = event.exception && event.exception.values && event.exception.values[0];
                    var matches;

                    if (exception) {
                      matches = angularPattern.exec(exception.value || '');
                      if (matches) {
                        // This type now becomes something like: $rootScope:inprog
                        exception.type = matches[1];
                        exception.value = matches[2];
                        event.message = exception.type + ': ' + exception.value;
                        // auto set a new tag specifically for the angular error url
                        event.extra = angular.extend({}, event.extra, { angularDocs: matches[3].substr(0, 250) });
                      }
                    }
                    return event;
                  });
                  sentry.captureException(ex);
                });
              });
          })
          .catch((err) => {
            $log.error('[7Shop.Bootstrap] Failed to load sentry integration', {
              upstream_code: err.data?.httpCode || err.data?.code || err.response?.status || err.status,
              upstream_message: err.data?.message || err.response?.message || err.message || err.statusText
            });
          });
      }

      $delegate(ex, cause);
    };
  });

  $translateProvider.translations(
    'local',
    {} // we do not have translation in this moment, they will be loaded after ShopBootstrap.boot is done
  );
  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.preferredLanguage('local');
  $translateProvider.useLoader('shopLanugageLoader', {});

  $urlServiceProvider.rules.otherwise('/');

  $stateProvider.state('app', {
    url: '/',
    template: '<div class="w-100 h-100" ui-view=""></div>',
    controllerAs: 'app',
    controller: function (
      $rootScope,
      $log,
      $q,
      $window,
      $state,
      ShopBootstrap,
      IframeBootstrap,
      bootData,
      Modules,
      GravitySettings,
      loaderService
    ) {
      var waitForIframeLoad = null;
      var needsInstallation = bootData && bootData.needInstallation === true;
      if (IframeBootstrap.isIframeIntegration()) {
        // we are integration, let's initilize communication
        waitForIframeLoad = IframeBootstrap.boot({
          needsInstallation: needsInstallation
        });
      }

      $q.when(waitForIframeLoad).then(function () {
        // lazyLoad modules, position: beforeAppReady
        Modules.loadModulesByPosition('beforeAppReady', GravitySettings)
          .finally(function () {
            var needInstallationFlag = $window
              .ShopEnvironment
              .getFlagState($window.ShopEnvironment.FLAGS.NEED_INSTALLATION);

            if (needInstallationFlag?.active) {
              Modules.loadModule('installation').then(() => $state.go('app.install'));
            } else if (!needsInstallation && sessionStorage.shopToken) {
              loaderService.showLoader({
                safeTimerDelay: 20000,
                forceLoader: true
              });
              ShopBootstrap.handleSessionLogin(sessionStorage.shopToken).finally(() => {
                $log.info('[7Shop.Bootstrap] Finished with loading loadMainApp.');
                loaderService.endLoader({
                  forceLoader: false
                });
              });
            } else {
              ShopBootstrap.loadLogin();
            }

            /**
            * Emits event when Shop has loaded neccessry data and it is
            * ready for interaction
            * @event "7S:App.Ready"
            */
            $rootScope.$emit('7S:App.Ready', {});
          });
      }).catch(function (err) {
        $log.error('[7Shop.Bootstrap] Failed loading iframe module', {
          upstream_code: err.data?.httpCode || err.data?.code || err.response?.status || err.status,
          upstream_message: err.data?.message || err.response?.message || err.message || err.statusText
        });
      });
    },
    resolve: {
      bootData: function (
        $window,
        $q,
        $translate,
        ShopBootstrap,
        Modules,
        Touch,
        ShopEnvironment
      ) {
        var needInstallationFlag = $window
          .ShopEnvironment
          .getFlagState($window.ShopEnvironment.FLAGS.NEED_INSTALLATION);
        var touchModulePromise = null;

        // app is in standalone mode and it needs installation
        if (needInstallationFlag && needInstallationFlag.active === true) {
          if (ShopEnvironment.isNativeTouch()) {
            Touch.setInTouch();
            touchModulePromise = Modules.loadModule('7Shop.Touch');
          }
          ShopBootstrap.loadTheme($window.ShopEnvironment.getUrlFlag('theme'));
          // fetch translations from API because we aren't fetching company bootstrap in
          // installation mode
          return $q.all([
            ShopBootstrap.fetchTranslations(),
            touchModulePromise
          ]).then(function (response) {
            ShopBootstrap.getBootstrapData().translations = response[0].data;
            // refresh so shopLanguageLoader can re-register translations
            $translate.refresh();
            return $q.resolve({ needInstallation: true });
          }).catch(function () {
            return $q.resolve({ needInstallation: true });
          });
        }

        return ShopBootstrap.boot();
      }
    }
  });
}).run(function (
  $rootScope,
  $exceptionHandler
) {
  // Because ui-route resolve doesn't throw error, this is a workaround to catch them
  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
    $exceptionHandler(error);
  });
});
