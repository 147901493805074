(function () {
  angular.module('7Shop.Core')
    .service('calculatorService', calculatorService);

  /**
   * @memberOf module:"7Shop.Core"
   * @constructor calculatorService
   */
  function calculatorService($injector, LocalStorage, Modules) {
    var self = this;
    var init = function () {
      var pinned = false;

      // Check if pinnedCalculator flag is set
      LocalStorage.getItem('settings.pinnedCalculator').then(function (flag) {
        // If not, set pinnedCalculator to false by default
        if (!flag) {
          LocalStorage.setItem('settings.pinnedCalculator', pinned);
        } else {
          pinned = JSON.parse(flag);
        }

        if (Modules.isModuleEnabled('googleAnalytics')) {
          $injector.get('Analytics').trackEvent('Calculator', 'Pinned on init', pinned ? 'Yes' : 'No');
        }

        // Set exposed calculator.pinned property value
        self.calculator.pinned = pinned;
      });
    };

    this.postResetHooks = [];

    /**
     * @memberOf module:"7Shop.Core".calculatorService
     * @type {{ pinned: boolean}}
     */
    this.calculator = {
      pinned: false,
      overlayTemplate: '',
      overlayTemplateVisible: false
    };

    /**
     * @memberOf module:"7Shop.Core".calculatorService
     * @param {boolean} flag
     */
    this.setPinnedFlag = function (flag) {
      this.calculator.pinned = flag;
      LocalStorage.setItem('settings.pinnedCalculator', flag);
    };

    /**
     * @memberOf module:"7Shop.Core".calculatorService
     * @param {string} template
     */
    this.setOverlayTemplate = function (template) {
      this.calculator.overlayTemplate = template;
    };

    /**
     * @memberOf module:"7Shop.Core".calculatorService
     * @param {boolean} visibility
     */
    this.setOverlayTemplateVisibility = function (visibility) {
      this.calculator.overlayTemplateVisible = visibility;
    };

    /**
     * @memberOf module:"7Shop.Core".calculatorService
     * @param {Function} fn
     */
    this.registerPostResetHook = function (fn) {
      if (typeof fn === 'function') {
        this.postResetHooks.push(fn);
      }
      return false;
    };

    /**
     * @memberOf module:"7Shop.Core".calculatorService
     */
    this.executePostResetHooks = function () {
      if (!this.postResetHooks.length) return;
      angular.forEach(this.postResetHooks, function (hook) {
        hook();
      });
    };

    init();
  }
})();
