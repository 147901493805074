(function () {
  angular.module('7Shop.Common').factory('barcodeService', barcodeService);

  function barcodeService(
    $log,
    $rootScope
  ) {
    var code = [];
    const config = {
      regex: {
        pattern: /^[A-Za-z0-9.-]$/,
        flags: 'i'
      }
    };

    function resetCode() {
      code = [];
    }

    function formCode(e) {
      $log.debug('[7Shop.Common] barcodeService read next code', {
        keyCode: e.keyCode,
        letter: e.key
      });
      code.push(e);
    }

    function checkCode() {
      var finalCode = '';
      var isBarcodeCard = code.some(function (eventData) {
        return eventData.key === '%';
      });
      const whitelistedKeys = new RegExp(config.regex.pattern, config.regex.flags);

      code.forEach(function (e) {
        if (whitelistedKeys.test(e.key)) {
          finalCode += e.key;
        }
      });

      emitCode(finalCode, isBarcodeCard);
    }

    function emitCode(finalCode, isBarcodeCard) {
      $log.info('[7Shop.Common] barcodeService Scanned and emitting code with suffix', {
        ticket_code: finalCode,
        isBarcodeCard
      });
      $rootScope.$emit('7S:Scanner.ScanningFinished', {
        callSign: {
          sufix: isBarcodeCard ? '%' : ''
        },
        type: 'barcode',
        data: {
          code: finalCode
        }
      });
    }

    return {
      resetCode,
      formCode,
      checkCode,
      emitCode
    };
  }
})();
